import React from 'react';
import './Footer.css';
import { motion } from 'framer-motion';
import { Link as Linkto } from 'react-router-dom';
import Contactme from "./Contactmepopup";

const Footer = () => {
  
  return (
    <motion.footer 
      className="footer"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1.2, ease: "easeOut" }}
    >
      <div className="container">
        <div className="footer-top">
          <div className="brand pt-3">
            <motion.h2 
              initial={{ opacity: 0,  }}
              animate={{ opacity: 1, rotate: 0 }}
              transition={{ duration: 1.5, ease: "easeOut" }}
            >
              Sharif Jamo
            </motion.h2>
            <p className='pb-5'>Creative Developer &amp; Designer</p>
          </div>
          <div className="social row">
          <h5>Social</h5>
          <a className='pt-1 mb-1' href="https://www.behance.net/sharefjammo" target="_blank" rel="noopener noreferrer">Behance</a> 
          <a className='pt-1 mb-1' href="https://www.linkedin.com/in/sharif-jamo-617648225/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          <a className='pt-1 mb-1' href="https://github.com/sharif-git-dev" target="_blank" rel="noopener noreferrer">Github</a>
          </div>
        
          <div className="social row">
          <h5>Pages</h5>
            <Linkto className='pt-1 mb-1' to="/Services" smooth duration={500}>Services</Linkto>
            <Linkto className='pt-1 mb-1' to="/Portfolio" smooth duration={500}>Portfolio</Linkto>
            <Linkto className='pt-1 mb-1' to="/About" smooth duration={500}>About</Linkto>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2025 My Portfolio. All Rights Reserved.</p>
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;
