import React from 'react';

export function Sorcero () {
  return (
    <div className='bg-white text-center'>
      <h1>Sorcero Project</h1>
      <p>Project Overview </p>
      <p>More content goes here...</p>
    </div>
  );
};

export default Sorcero;
